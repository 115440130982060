<template>
    <div class="RenWenBox">
        <div class="ConBox" v-if="customList.length>0">
            <div style="height: 5rem;"></div>
            <!-- 标题 -->
            <div style="width: 80%;display: flex;justify-content: center;font-weight: 600;font-size: 3vh;">古镇民俗文化</div>
            <!-- 列表页 -->
            <div style="width: 77%;margin-top: 3vh;">
                <div style="width: 98%;margin: 2vh auto;height: 18vh;display: flex;">
                    <!-- 列表盒子 -->
                    <div style="width: 18%;height: 100%;margin-right: 3vh;cursor: pointer;" v-for="(item,index) in customList" :key="index" @click="changecustomCurrent(index)">
                        <div style="width: 100%;height: 75%;">
                            <img :src="item.logoUrl" style="width:100%;height: 100%;">
                        </div>
                        <div style="width: 100%;height: 25%;display: flex;justify-content: center;align-items: center;font-size: 2vh;">{{ item.title }}</div>
                    </div>
                </div>
            </div>
            <!-- 分割线 -->
            <div style="width: 95%;border: 1px solid rgb(151,151,151);"></div>
            <!-- 内容切换 -->
            <div style="width: 80%;display: flex;justify-content: center;">
                <div style="display: flex;justify-content: space-between;">
                    <div v-for="(TabList, index) in TabList" :key="index" :style="{ backgroundImage: 'url(' + TabList.img + ')' }" style="background-size: 100% 100%;padding: 0.8vh 5vh;color: rgb(101,101,101);" :class="{ actived: TabIndex == index }" @click="CheckTab(index)">
                        <div style="position: relative;top: -0.1rem;cursor: pointer;">{{TabList.name}}</div>
                    </div>
                </div>
            </div>
            <!-- 简介内容 -->
            <div v-if="TabIndex == 0" style="width: 80%;min-height:21rem; ;margin: 2rem 0;display: flex;justify-content: space-between;">
                <BriefIntroduction :Introduction="customCurrent"></BriefIntroduction>
            </div>
            <!-- 图片内容 -->
            <div v-else-if="TabIndex == 1" style="width: 80%;min-height:53vh;display: flex;flex-direction: column;align-items: center;">
                <ImgSwiper :ImgList="imgList"></ImgSwiper>
            </div>
            <!-- 视频集 -->
            <div v-else-if="TabIndex == 2" style="width: 100%;display: flex;flex-direction: column;align-items: center;">
                <VideoBox :VideoList="videoList"></VideoBox>
            </div>
            <div v-else style="width: 80%;min-height:53vh;">
                暂无数据~
            </div>
        </div>
        <div class="ConBox" v-else style="min-height: 100vh;">
            <div style="height: 5rem;"></div>
            <div>暂无数据~</div>
        </div>
    </div>
</template>
  
  <script>
import ImgSwiper from './components/ImgSwiper.vue'
import VideoBox from './components/VideoBox.vue'
import BriefIntroduction from './components/BriefIntroduction.vue'

export default {
    components: { ImgSwiper, VideoBox, BriefIntroduction },
    data () {
        return {
            TabIndex: 0,
            TabList: [
                {
                    img: 'http://oss-prod.lzxx8848.com/image/Scenicspace/XZRenWenLeft.png',
                    imgTwo: 'http://oss-prod.lzxx8848.com/image/Scenicspace/XZRenWenLeft.png',
                    name: '简介'
                },
                {
                    img: 'http://oss-prod.lzxx8848.com/image/Scenicspace/RenWenZhong.png',
                    imgTwo: 'http://oss-prod.lzxx8848.com/image/Scenicspace/XZRenWenZhong.png',
                    name: '照片'
                },
                {
                    img: 'http://oss-prod.lzxx8848.com/image/Scenicspace/RenWenRight.png',
                    imgTwo: require('../../assets/Scenicspace/XZRenWenRight.png'),
                    name: '视频'
                }
            ],
            TabListTwo: [
                {
                    img: 'http://oss-prod.lzxx8848.com/image/Scenicspace/RenWenLeft.png',
                    imgTwo: 'http://oss-prod.lzxx8848.com/image/Scenicspace/XZRenWenLeft.png',
                    name: '简介'
                },
                {
                    img: 'http://oss-prod.lzxx8848.com/image/Scenicspace/RenWenZhong.png',
                    imgTwo: 'http://oss-prod.lzxx8848.com/image/Scenicspace/XZRenWenZhong.png',
                    name: '照片'
                },
                {
                    img: 'http://oss-prod.lzxx8848.com/image/Scenicspace/RenWenRight.png',
                    imgTwo: 'http://oss-prod.lzxx8848.com/image/Scenicspace/XZRenWenRight.png',
                    name: '视频'
                }
            ],
            customList: [],//人文故事列表
            customCurrent: {},//当前选中的人物
            imgList: [],//图片集
            imgListShow: [],//展示的缩略图
            imgTotalPage: 0, //总页数
            imgPageNum: 5, //每页显示条数
            imgPage: 1, //当前页
            imgIdx: 0,//当前图片
            videoList: [],//视频集
            videoIdx: 0,
        }
    },
    mounted () {
        this.GetList()
    },
    methods: {
        // 民俗文化首页数据
        GetList () {
            var axios = require('axios')
            var config = {
                method: 'get',
                url:
                    this.$Schttp +
                    '/vtp/app/folkCustoms/scenicFolkCustomsIndex/' +
                    this.$route.query.scenicId,
                headers: {},
            }
            axios(config)
                .then((res) => {
                    if (res.data.data == null) {
                    } else {
                        this.customList = res.data.data.customs
                        if (this.customList.length > 0) {
                            this.customCurrent = this.customList[0]
                            // 获取图片集
                            this.GetImgList(this.customList[0].customsId)
                        }

                    }
                })
                .catch(function (error) { })
        },
        // 切换图片
        changecustomCurrent (idx) {
            this.customCurrent = this.customList[idx]
            this.GetImgList(this.customList[idx].customsId)
        },
        // 获取人物图片集与视频集
        GetImgList (id) {
            var axios = require('axios')
            var config = {
                method: 'get',
                url:
                    this.$Schttp +
                    '/vtp/app/folkCustoms/detail/' + id,
                headers: {},
            }
            axios(config)
                .then((res) => {
                    this.imgList = res.data.data.imgs
                    this.imgTotalPage = Math.ceil(this.imgList.length / this.imgPageNum) || 1
                    this.imgListShow = this.imgList.slice(0, this.imgPageNum)
                    this.videoList = res.data.data.videos
                    this.imgIdx = 0
                    this.videoIdx = 0
                })
                .catch(function (error) { })
        },
        CheckTab (index) {
            this.TabIndex = index
            this.TabList[0].img = 'http://oss-prod.lzxx8848.com/image/Scenicspace/RenWenLeft.png'
            this.TabList[1].img = 'http://oss-prod.lzxx8848.com/image/Scenicspace/RenWenZhong.png'
            this.TabList[2].img = 'http://oss-prod.lzxx8848.com/image/Scenicspace/RenWenRight.png'
            if (index == 0) {
                this.TabList[index].img = this.TabListTwo[index].imgTwo
            }
            if (index == 1) {
                this.TabList[index].img = this.TabListTwo[index].imgTwo
            }
            if (index == 2) {
                this.TabList[index].img = this.TabListTwo[index].imgTwo
            }
        }
    }
}
  </script>
  
  <style lang="less" scoped>
.RenWenBox {
    width: 100%;
    min-height: 100%;
    background-image: url(../../assets/Scenicspace/RenWenBG.jpg);
    background-size: auto 100%; /* 自动调整宽度，高度为100% */
    background-size: cover;
    .ConBox {
        width: 70%;
        margin: 0 auto;

        background-color: rgba(255, 255, 255, 0.9);
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
    }
    .actived {
        color: white !important;
    }
}
</style>