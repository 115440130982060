<template>
    <div class="swiper" v-if="ImgList.length>0">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,index) in ImgList" :key="index">
                <div class="ImgBox">
                    <img :src="item.resourceUrl" style="width: 100%;height: 100%;">
                </div>
            </div>
        </div>
    </div>
    <div v-else>暂无数据！！！</div>
</template>
<script>
export default {
    props:['ImgList'],
    data(){
        return{}
    },
    mounted(){
        this.initSwiper()
        console.log(this.ImgList,'ImgList')
    },
    methods:{
        // 初始化
        initSwiper(){
            var mySwiper = new Swiper('.swiper', {
                initialSlide:0,//设定初始化时slide的索引
                grabCursor:true,//手
                watchOverflow:true,//当没有足够的slide切换时，例如只有1个slide，swiper会失效且隐藏导航
                effect : 'coverflow',
                slidesPerView: 3,
                centeredSlides: true,
                coverflowEffect: {
                    rotate: -10,
                    stretch: -20,
                    depth: 100,
                    modifier: 2,
                    slideShadows : false
                },
            })
        }
    }
}
</script>
<style lang="less" scoped>
.swiper{
    width: 100%;
    margin:2rem 0;
    .swiper-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        .swiper-slide{
            .ImgBox{
                width: 100% ;
                height: 100%;
       
            }
           
        }
    }
}

</style>